import {makeObservable, observable, action} from 'mobx';
import {JsonMail} from 'src/api/JsonMailApi';

export interface ExerciseScore {
  isCorrect: boolean,
  points: number,
}

/** A mail set class containing JSON mails as a list **/
export default class MailSet {
  /**
   * point definition for each case
   * PHISHING_MAIL_CORRECT: identify PHISHing mail as spam
   * PHISHING_MAIL_WRONG: identify phishing mail as trusted
   * TRUSTED_MAIL_CORRECT: identify trusted mail as trusted
   * TRUSTED_MAIL_WRONG: identify trusted mail as spam
   */
  POINTS = {
    PHISHING_MAIL_CORRECT: 10,
    PHISHING_MAIL_WRONG: -10,
    TRUSTED_MAIL_CORRECT: 10,
    TRUSTED_MAIL_WRONG: 0,
    HINT_COSTS: -2,
  };

  mailData: JsonMail[] = [];
  activeMail?: JsonMail;
  title: string;

  /** Constructor stores mail Data array **/
  constructor(mails: JsonMail[], title:string) {
    this.mailData = mails;
    this.title = title;
    makeObservable(this, {
      mailData: observable,
      activeMail: observable,
      deleteEntry: action,
      score: action,
      updateActiveMail: action,
    });
  }

  /** Delete a json mail from the list **/
  deleteEntry(mail: JsonMail) {
    const index = this.mailData.indexOf(mail);
    this.mailData.splice(index, 1);

    if (mail === this.activeMail) {
      if (this.mailData.length > index) {
        this.activeMail = this.mailData[index];
      } else if (this.mailData.length > 0) {
        this.activeMail = this.mailData[index-1];
      } else {
        this.activeMail = undefined;
      }
    }
  }

  /** Calculate the Score an return a tupel of correctness and points**/
  score(isPhishing: boolean) : ExerciseScore {
    const score: ExerciseScore = {
      isCorrect: false,
      points: 0,
    };
    if (this.activeMail?.isPhishing && isPhishing) {
      score.isCorrect = true;
      score.points = this.POINTS.PHISHING_MAIL_CORRECT;
    } else if (this.activeMail?.isPhishing && !isPhishing) {
      // score.isCorrect is already false
      score.points = this.POINTS.PHISHING_MAIL_WRONG;
    } else if (!this.activeMail?.isPhishing && !isPhishing) {
      score.isCorrect = true;
      score.points = this.POINTS.TRUSTED_MAIL_CORRECT;
    } else {
      // exactly entered if !mail.isPhishing && isPhishing
      // score.isCorrect is already false
      score.points = this.POINTS.TRUSTED_MAIL_WRONG;
    }
    return score;
  }

  /** update the active mail to the specified one **/
  updateActiveMail(mail?: JsonMail) {
    this.activeMail = mail;
  }
}
