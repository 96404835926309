import React from 'react';
import './ContextMenu.scss';

export interface ContextMenuEntry<T> {
  description: string,
  action: (element: T) => void,
}

interface Position {
  xPosition: number,
  yPosition: number,
}

interface ContextMenuProps<T> {
  element: T,
  entries: ContextMenuEntry<T>[],
}

interface ContextMenuState<T> {
  position: Position;
  visible: boolean;
  element: T
}

/** A context menu for arbitrary elements, displays options
 * as drop down element. It uses x and y Positioning, to be
 * placed. The entries are realized via callbacks.
 */
class ContextMenu<T> extends React.Component<ContextMenuProps<T>,
  ContextMenuState<T>> {
  private wrapperRef: React.RefObject<HTMLUListElement>;

  /** Constructor using props **/
  constructor(props: ContextMenuProps<T>) {
    super(props);
    this.state = {
      position: {xPosition: 0, yPosition: 0},
      visible: false,
      element: props.element,
    };
    this.wrapperRef = React.createRef();
  }

  /** Show the menu at postion x,y **/
  showAt = (position: Position) => {
    this.setState({
      visible: true,
      position: position,
    });
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event: MouseEvent) => {
    if (this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target as Node)) {
      this.hide();
    }
  }

  /** Hide the menu **/
  hide = () => {
    document.removeEventListener('mousedown', this.handleClickOutside);
    this.setState({visible: false});
  }

  /** Update the associated element **/
  updateElement = (element: T) => {
    this.setState({element: element});
  }

  /** update on element select **/
  entrySelect = (action: (element: T) => void) => {
    this.hide();
    action(this.props.element);
  }

  /** Render the list of entries**/
  render() {
    return (
      <ul ref={this.wrapperRef} className="ContextMenu" style={{
        left: `${this.state.position.xPosition}px`,
        top: `${this.state.position.yPosition}px`,
        display: this.state.visible? 'block': 'none',
      }}>
        {this.props.entries.map((entry, index) => (
          <li key={index} onClick={()=> this.entrySelect(entry.action)}>
            {entry.description}
          </li>
        ))}
      </ul>
    );
  }
}

export default ContextMenu;
