import React from 'react';
import './PhishingPage.scss';
import warning from 'src/assets/PhishingQuiz/Warning.svg';

/** Phishing landing Page**/
class PhishingPage extends React.Component {
  /** Simple render function **/
  render() {
    return (
      <div className="PhishingPage">
        <div className="content">
          <img src={warning} alt="Ausrufe Zeichen"/>
          <h1>Dies ist eine Phishing Seite</h1>
          <div>
            <h2>
              Warum hielten Sie den Link für Legitim?
            </h2>
            <h3>
              Klicken Sie nur auf Links, wenn sie sich sicher sind,
              dass diese legitim sind.
            </h3>
            <h3>
              Phishing Links Bergen die folgenden Gefahren:
            </h3>
            <ul>
              <li>Dem Abgreifen von Passwörtern mit gefälschten
              Login-Masken, dadurch
              können Angreifer Zugriff auf Ihren Computer oder
              weitere Portale erhalten.
              </li>
              <li>Dem Versuch, Schadsoftware auf ihren Computer
              zu Installieren, dies
              kann als Ausgangspunkt dienen, um weitere Geräte
              der Firma zu infizieren.
              </li>
              <li>Dem Sammeln von Informationen über Sie und
              Ihren Betrieb, welche in weiteren Angriffen verwendet
              werden können.
              </li>
            </ul>
            <h3>
              Legitime Links erkennen Sie an folgenden Merkmalen:
            </h3>
            <ul>
              <li>
                Die letzte Link-Endung (bspw. .de, .com) vor dem
                ersten &bdquo;/&ldquo; zählt. Angreifer versuchen oftmals
                diese zu maskieren, wie beispielsweise
                google.com.evilcorp.xyz/.
              </li>
              <li>
                Links können maskiert werden und auf eine andere
                Seite als in der E-Mail steht weiterleiten. Ihr Browser
                zeigt Ihnen unten links die Ziel-Adresse eines Links an,
                wenn Sie mit der Maus über den Link fahren.
              </li>
              <li>
                Falls Sie eigentlich bekannte Seiten besuchen, achten
                Sie besonders auf Rechtschreibfehler,
                fehlende Bilder und unübliches verändertes Aussehen der
                Seite.
              </li>
              <li>
                Weitere Hinweise auf Phishing in der Mail selbst. Überprüfen
                Sie bei Links nochmals die E-Mail selbst auf weitere Phishing
                Hinweise.
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default PhishingPage;
