import React from 'react';

import './MailHeaderView.scss';
import {JsonMail, MailIssues} from 'src/api/JsonMailApi';
import {getIssueText} from '../../logic/InstantFeedbackText';
import DefaultAvatar from 'src/assets/PhishingQuiz/user.png';
import {generateDayDateTimeString} from '../../utils/displayLogic';
import Paperclip from 'src/assets/PhishingQuiz/paperclip_cc0.svg';

interface MailHeaderViewProps {
  mail: JsonMail,
  feedbackIsActive: boolean,
  firstName: string,
  lastName: string,
}

/**
 * PhishingQuiz MailView Component
 *
 * This displays an MailView using
 * a json, which contains email header
 * fields and a html body.
 */
class MailHeaderView extends React.Component<MailHeaderViewProps> {
  /** Constructor setting props:
   * Make sure that the mail is no user defined content
   * to avoid XSS in the Body View
   **/

  feedback: Map<string, string>;
  /** Default constructor **/
  constructor(props: MailHeaderViewProps) {
    super(props);
    this.feedback = getIssueText(props.mail);
  }

  isIssue = (type: MailIssues) : string => {
    if (this.props.mail.issueTags && this.props.feedbackIsActive) {
      if (this.props.mail.issueTags.includes(type)) {
        if (this.props.mail.isPhishing) {
          return 'ErrorTooltip--phishing';
        } else {
          return 'ErrorTooltip--regular';
        }
      }
    }
    return '';
  }

  /** onClick Function for Attachment element
   * currently opens new tab with info page
   */
  onAttachmentClick = () => {
    if (this.props.mail.isPhishing) {
      window.open('/PhishingQuiz/PhishingAttachment', '_blank');
    } else {
      window.open('/PhishingQuiz/TrustedAttachment', '_blank');
    }
  }

  /** A render function using the json data **/
  render() {
    const dateTime = generateDayDateTimeString(this.props.mail.date);
    if (!this.props.mail.to.startsWith('<')) {
      this.props.mail.to = '<' + this.props.mail.to;
    }
    if (!this.props.mail.to.endsWith('>')) {
      this.props.mail.to = this.props.mail.to + '>';
    }

    const attachment = this.props.mail.attachment? (
      <div className={`${this.isIssue(MailIssues.attachment)} Attachment`}
        data-text={`${this.feedback.get('attachment')}`}
        onClick={this.onAttachmentClick}>
        <img src={Paperclip} alt='Büroklammer'></img>
        <div>{this.props.mail.attachment}</div>
      </div>):undefined;


    const mailAddress = `${this.props.firstName}.${this.props.lastName}@kmu.de`;
    const useMail = this.props.mail.issueTags &&
      this.props.mail.issueTags.includes(MailIssues.recipient);
    return (
      <div className="MailHeaderView">
        <div className="Subject">
          <span className={`${this.isIssue(MailIssues.topic)}`}
            data-text={`${this.feedback.get('topic')}`}>
            {this.props.mail.subject}</span></div>
        <div className="MailHeaderInfo">
          <img className="FromAvatar" src={DefaultAvatar}/>
          <div className="HeaderDetailBlock">
            <div className="From">
              <span className={`${this.isIssue(MailIssues.address)}`}
                data-text={`${this.feedback.get('address')}`}>
                {this.props.mail.from}</span>
            </div>
            <div className="DateTime">{dateTime}</div>
            <div className={`Receivers`}>
              <span className={`${this.isIssue(MailIssues.recipient)}`}
                data-text={`${this.feedback.get('recipient')}`}>
                {
                  useMail?
                  this.props.mail.to:
                  mailAddress
                }</span>
            </div>
          </div>
          {attachment}
        </div>
      </div>
    );
  }
}

export default MailHeaderView;
