import React from 'react';
import {v4 as uuidv4} from 'uuid';
import './Home.scss';


const QUESTIONARY_LINK = 'https://www.umfrageonline.com/c/arwkceba';

/** UUID gen and Redirect function **/
function redirectToID() {
  const id = uuidv4();
  const quizType = Math.floor(Math.random() * 4);

  const link = QUESTIONARY_LINK + `?qID=${quizType}&userID=${id}`;
  window.location.href = link;
}

/** Home component with link elements*/
function Home() {
  return (
    <div className="Home">
      <div className="HomeContent">
        <h1>Gamification Nutzerumfrage</h1>
        <p>
          Im Rahmen des Projekts „BAK Game“ führen wir
          eine Befragung zu unserem Phishing-Quiz durch.
          Die Projektpartner bestehen aus der Hochschule Aalen
          und der Technischen Akademie Schwäbisch Gmünd. Das BAK Game
          ist ein vom DLR gefördertes Projekt, dass Weiterbildungskonzepte
          auf Basis Gamifizierten-Lernens im Bereich IT-Sicherheit
          für KMU entwickelt.
        </p>
        <h3> Die Befragung steht aus drei Teilen:</h3>
        <ol>
          <li>Vorab-Fragebogen</li>
          <li>Absolvieren des Phishing-Quiz</li>
          <li>Abschluss-Fragebogen</li>
        </ol>
        <p>
          Wenn Sie Fragen zu der Umfrage haben,
          senden Sie uns eine E-Mail an <a href="mailto:info@bakgame.de">
            info@bakgame.de
          </a>
        </p>
        <br/>
        <p>
          Weitere Informationen zu unserem Projekt gibt es unter:<br/>
          <a href="https://www.bakgame.de">
            www.bakgame.de
          </a>
        </p>
        <br/>
        <p>
        Vielen Dank für Ihre Teilnahme!
        </p>
        <button onClick={redirectToID}>START</button>
      </div>
    </div>
  );
}

export default Home;
