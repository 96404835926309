import {JsonMail} from 'src/api/JsonMailApi';

export interface SubmittedSolution {
  mail: JsonMail,
  isCorrect: boolean,
}

/** class to organize solutions level-wise */
export class SolutionLevel {
  solutions: SubmittedSolution[]=[];
  levelTitle: string;
  levelNumber:number;

  /** default constructor */
  constructor(levelTitle:string, levelNumber:number) {
    this.levelTitle = levelTitle;
    this.levelNumber = levelNumber;
  }
}

/** A class to store the history of the Game and showing a review**/
class GameHistory {
  solutions: SolutionLevel[] = [];
  maxPoints = 0;
  currentSolutionLevel: SolutionLevel|undefined;

  /** Add a submitted solution, with its level to the History **/
  add(solution: SubmittedSolution, maxPoints: number) {
    this.currentSolutionLevel?.solutions.push(solution);
    this.maxPoints += maxPoints;
  }

  /** add new solutionLevel
   * should be called after selecting next level
   */
  addSolutionLevel(levelTitle: string, levelNumber: number) {
    this.currentSolutionLevel = new SolutionLevel(levelTitle, levelNumber);
  }

  /**
   * push current solutionLevel to solutions array if exists
   */
  pushCurrentSolutionLevel() {
    if (this.currentSolutionLevel) {
      this.solutions.push(this.currentSolutionLevel);
    }
    this.currentSolutionLevel = undefined;
  }

  /** get all submitted solutions for a declared level **/
  getLevel(level: number): SolutionLevel {
    return this.solutions[level];
  }
}

export default GameHistory;
