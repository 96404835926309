import React from 'react';
import './CompanyPage.scss';

/** Phishing landing Page**/
class TrustedAttachmentPage extends React.Component {
  /** Simple render function **/
  render() {
    return (
      <div className="CompanyPage">
        <div className="content">
          <h1>Diese Email enthält einen vertrauenswürdigen Anhang</h1>
          <div>
            <h2>
              Woran haben Sie erkannt, dass es sich bei dem Anhang
              um einen legitimen Anhang gehandelt hat?
            </h2>
            <h3>
              Öffnen Sie nur Anhänge, wenn sie sich sicher sind,
              dass diese legitim sind.
            </h3>
            <h3>
              Legitime Anhänge erkennen Sie an den folgenden Merkmalen:
            </h3>
            <ul>
              <li>
                Datei-Typen können verschleiert werden. Überprüfen Sie daher
                nochmal den Datei-Typ vor dem Öffnen. Sie können hierzu auch
                die Datei-Endung anschauen. Achten Sie allerdings darauf, dass
                standardmäßig Datei-Endungen nicht angezeigt werden. Aus diesem
                Grund nutzen Angreifer gerne Namen wie &quot;datei.pdf&quot;,
                welche dann aber mit angezeigter Datei-Endung
                &quot;datei.pdf.exe&quot; sein können.
              </li>
              <li>
                Pdf, Excel oder Word Dokumente können
                Schad-Code enthalten. Falls eingestellt, sollten
                manche Dokumenttypen wie Excel oder Word
                automatisch in einem sicheren Modus
                gestartet werden. Sobald Sie die Bearbeitungsfunktion
                aktivieren, wird dieser deaktiviert und eventueller
                Schad-Code wird sofort ausgeführt.
              </li>
              <li>
                Weitere Hinweise auf Phishing in der Mail selbst. Seien Sie
                bei jeglicher Art von Dateien besonders vorsichtig und prüfen
                Sie nochmals vor dem Öffnen die E-Mail.
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default TrustedAttachmentPage;
