import React from 'react';
import PhishingQuiz from 'src/components/PhishingQuiz/PhishingQuiz';
import PhishingPage from
  'src/components/PhishingQuiz/LandingPages/PhishingPage';
import CompanyPage from
  'src/components/PhishingQuiz/LandingPages/CompanyPage';
import Home from 'src/components/Home/Home';
import PhishingAttachmentPage from
  'src/components/PhishingQuiz/LandingPages/PhishingAttachmentPage';
import TrustedAttachmentPage from
  'src/components/PhishingQuiz/LandingPages/TrustedAttachmentPage';

import {
  Route, BrowserRouter as Router, Switch,
} from 'react-router-dom';

/** MainRouter component contains top level links to each game */
function MainRouter() {
  return (<Router>

    <Switch>
      <Route>
        <Route exact path='/' component={Home} />
        <Switch>
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Route path='/PhishingQuiz/PhishingPage' component={PhishingPage} />
          <Route path='/PhishingQuiz/CompanyPage' component={CompanyPage} />
          <Route path='/PhishingQuiz/PhishingAttachment'
            component={PhishingAttachmentPage} />
          <Route path='/PhishingQuiz/TrustedAttachment'
            component={TrustedAttachmentPage} />
          <Route path='/PhishingQuiz' component={PhishingQuiz} />
        </Switch>
      </Route>
    </Switch>
  </Router>);
}

export default MainRouter;
