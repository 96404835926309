import React from 'react';
import {MouseEvent} from 'react';
import ListView from 'src/utils/ListView/ListView';
import {JsonMail} from '../../../api/JsonMailApi';
import {splitFromField} from '../utils/displayLogic';
import GameState from '../logic/GameState';
import './MailListView.scss';
import {generateDayDateString} from '../utils/displayLogic';
import ContextMenu from 'src/utils/ContextMenu/ContextMenu';
import PaperClip from 'src/assets/PhishingQuiz/paperclip_cc0.svg';

interface MailListViewProps {
  gameState: GameState,
}


/**
 * A Class to view the List of Emails as clickable elements
 */
class MailListView extends React.Component<MailListViewProps> {
  listReference: React.RefObject<ListView<JsonMail>>;
  contextMenuReference: React.RefObject<ContextMenu<JsonMail>>;

  /** Constructor taking the score as Number **/
  constructor(props: MailListViewProps) {
    super(props);
    this.listReference = React.createRef();
    this.contextMenuReference = React.createRef();
  }

  /** create MailListItem from JsonMail */
  createMailListItem = (mail: JsonMail) => {
    const attachmentImg = mail.attachment?(<img
      className='Attachment' src={PaperClip} alt='Büroklammer'></img>):null;
    const date = generateDayDateString(mail.date);
    return (
      <div className="HeaderBlock"
        onContextMenu={
          (event: MouseEvent) =>this.showMailContext(mail, event)}>
        <div className="FromAttachment">
          <div className="From" >{splitFromField(mail.from)}</div>
          {attachmentImg}
        </div>
        <div className="SubjDate">
          <div className="Subj">{mail.subject}</div>
          <div className="Date">{date}</div>
        </div>
      </div>
    );
  }

  /** Show mail context menu **/
  showMailContext = (mail: JsonMail, event:MouseEvent) => {
    event.preventDefault();
    this.contextMenuReference.current?.updateElement(mail);
    this.contextMenuReference.current?.showAt(
        {xPosition: event.pageX, yPosition: event.pageY});
  }

  /** Show mail header **/
  viewMailHeader = (mail: JsonMail) => {
    this.props.gameState.showMailHeader(mail);
  }

  /** Show mail Details at right side **/
  viewMailDetails = (mail?: JsonMail) => {
    if (!this.props.gameState.currentMailSet) {
      throw new Error('current mailset is null');
    }

    if (this.props.gameState.currentMailSet.activeMail == mail) {
      this.props.gameState.currentMailSet.updateActiveMail(undefined);
    } else {
      this.props.gameState.currentMailSet.updateActiveMail(mail);
    }
  }

  /** Render the List **/
  render() {
    if (this.props.gameState.currentMailSet?.mailData !== undefined) {
      return (
        <div>
          <ContextMenu<JsonMail>
            ref={this.contextMenuReference}
            element={this.props.gameState.currentMailSet.mailData[0]}
            entries={[{
              description: 'E-Mail Details anzeigen',
              action: this.viewMailHeader,
            }]}
          />
          <ListView<JsonMail> onClick={this.viewMailDetails}
            ref={this.listReference}
            renderEntry={(mail: JsonMail) => this.createMailListItem(mail)}
            list={this.props.gameState.currentMailSet.mailData} />
        </div>
      );
    }
  }

  updateActiveMail = (mail: JsonMail) => {
    this.viewMailDetails(mail);
    this.props.gameState.currentMailSet?.updateActiveMail(mail);
    this.listReference.current?.setActive(mail);
  }
}

export default MailListView;
