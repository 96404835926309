import React from 'react';
import {observer} from 'mobx-react';

import './PhishingQuiz.scss';
import ScoreView from 'src/utils/ScoreView/ScoreView';
import QuizContainer from './QuizContainer/QuizContainer';
import PhishingQuizExplainPage from
  './PhishingQuizExplainPage/PhishingQuizExplainPage';
import FinView from 'src/utils/FinView/FinView';
import LevelView from './LevelView/LevelView';
import MailListView from './MailListView/MailListView';
import MailListFeedbackOverlay from
  './MailListView/MailListFeedbackOverlay/MailListFeedbackOverlay';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BAKManLogo from 'src/assets/logo.svg';
import GameState from './logic/GameState';
import {GamePages} from './logic/GameState';
import getLevels from './logic/Levels';
import MailSet from './logic/MailSet';
import HintView from './HintView/HintView';
import HintWarn from './HintView/HintWarn';
import HeaderView from './HeaderView/HeaderView';
import PersonalisationMask from './PersonalisationMask/PersonalisationMask';

interface PhishingQuizProps {
  init: number | undefined,
}

/**
 * The PhishingQuiz Main Component
 */
@observer
class PhishingQuiz extends React.Component<PhishingQuizProps> {
  gameState: GameState;
  listView: React.RefObject<MailListView>;

  /** constructor initializes GameState and calls getLevels function */
  constructor(props: PhishingQuizProps) {
    super(props);
    this.listView = React.createRef();
    this.gameState = new GameState();
    this.phishingLinkClickEvent = this.phishingLinkClickEvent.bind(this);
  }

  /** Init state on mount async **/
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress);
    getLevels().then((levels: MailSet[]) => {
      this.gameState.levels = levels;
      this.gameState.init();
    });
    const querryParams = new URLSearchParams(window.location.search);
    const uid = '' + querryParams.get('userID');
    this.gameState.setUserID(uid);
    const gameType = '' + querryParams.get('qID');
    this.gameState.setGameType(parseInt(gameType));
  }

  /** unbind event listener **/
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  /** update the finish page state **/
  showCompleteToast = () => {
    toast.info('Level abgeschlossen', {autoClose: 2000, hideProgressBar: true});
  }

  /** show toast to display the hint cost **/
  showToastHint = () => {
    toast.info(`Tipp genutzt ${
      this.gameState.currentMailSet?.POINTS.HINT_COSTS} Punkte`,
    {autoClose: 2000, hideProgressBar: true});
  }

  /** Show a toast for the points **/
  showPointToast = (points: number, isCorrect: boolean) => {
    if (isCorrect) {
      toast.success(`✓ Korrekt: ${points} Punkte`, {
        hideProgressBar: true,
      });
    } else {
      toast.error(`✗ Falsch: ${points} Punkte`, {
        hideProgressBar: true,
      });
    }
  }

  /** Submit an exercise **/
  submitExercise = (isPhishing: boolean) => {
    if (!this.gameState.currentMailSet) {
      throw new Error('current mailset is null');
    }
    if (!this.gameState.currentMailSet.activeMail) {
      throw new Error('active mail is null');
    }
    const points = this.gameState.submit(isPhishing);
    this.showPointToast(points, points > 0 );
    if (points <= 0 &&
      (this.gameState.gameType == 1 || this.gameState.gameType == 3 ) ) {
      this.gameState.switchFeedback();
    } else {
      this.finalizeSubmission();
    }
  }

  finishReview = () => {
    this.finalizeSubmission();
    this.gameState.switchFeedback();
  }

  finalizeSubmission = () => {
    if (!this.gameState.currentMailSet) {
      throw new Error('current mailset is null');
    }
    if (!this.gameState.currentMailSet.activeMail) {
      throw new Error('active mail is null');
    }
    this.gameState.removeCurrentMail();
    if (this.gameState.currentMailSet.mailData.length == 0) {
      this.showCompleteToast();
      this.gameState.selectNextLevel();
    } else {
      this.listView?.current?.updateActiveMail(
          this.gameState.currentMailSet.mailData[0]);
    }
  }

  /** Returns span parent or body */
  findSpanParent(htmlElement: HTMLElement) : HTMLElement {
    while (htmlElement.parentElement) {
      if (htmlElement.nodeName !== 'SPAN') {
        htmlElement = htmlElement.parentElement;
      } else {
        return htmlElement;
      }
    }
    return htmlElement;
  }

  /** Show toast for Phishing Link */
  phishingLinkClickEvent(event: Event) : boolean {
    if (event) {
      const isPhishingLink = this.findSpanParent(event.target as HTMLElement).
          className.indexOf('Err') !== -1;
      const score = this.gameState.reduceScoreOnPhishingLink(isPhishingLink);
      if (isPhishingLink) {
        toast.error(`Phishing-Link: ${score} Punkte`, {
          hideProgressBar: true,
        });
        window.open('/PhishingQuiz/PhishingPage', '_blank');
      } else {
        window.open('/PhishingQuiz/CompanyPage', '_blank');
      }
    }
    // blocks event propagation to the link when used with onclick
    return false;
  }

  getContainerView = (): JSX.Element => {
    if (this.gameState.currentMailSet !== undefined &&
        this.gameState.currentMailSet.activeMail) {
      return (
        <QuizContainer
          mail={this.gameState.currentMailSet.activeMail}
          firstName={this.gameState.firstName}
          lastName={this.gameState.lastName}
          addressation={this.gameState.addressation}
          feedbackIsActive={this.gameState.activeFeedback}
          scoreFunction={this.submitExercise}
          continueFunction={this.finishReview}
          hintFunction={this.showHints}
          mailbodyLinkFunction={this.phishingLinkClickEvent}
        />);
    } else {
      return <div className="Empty">Wählen Sie eine E-Mail</div>;
    }
  }

  /** handle key press **/
  handleKeyPress = (e: KeyboardEvent) => {
    if (e.key == 'k' && !this.gameState.activeFeedback &&
      this.gameState.currentMailSet?.activeMail) {
      this.submitExercise(false);
    } else if (e.key == 'j' && !this.gameState.activeFeedback &&
      this.gameState.currentMailSet?.activeMail) {
      this.submitExercise(true);
    }
  }

  /** handle ok Explainpage **/
  handleOKExplainpage = () => {
    this.gameState.openPersonalisationMask();
  }

  /** Show the hint to the Mail set **/
  showHints = () => {
    if (!this.gameState.hintUnlocked) {
      this.gameState.toggleHintWarn();
    } else {
      this.showToastHint();
      this.gameState.togleHint();
    }
  }

  /** Render function **/
  render() {
    let gameBody;
    let levelView;
    let scoreView;
    if (this.gameState.page == GamePages.loading) {
      gameBody = <div><h1>Loading ...</h1></div>;
    } else if (this.gameState.page == GamePages.explain) {
      gameBody = <PhishingQuizExplainPage gameType={this.gameState.gameType}
        clickEvent={this.handleOKExplainpage}/>;
    } else if (this.gameState.page == GamePages.finish) {
      gameBody = (<div>
        <FinView userID={this.gameState.uuid} score={this.gameState.score}/>
      </div>);
    } else if (this.gameState.page == GamePages.personalisation) {
      gameBody = (<PersonalisationMask gameState={this.gameState}>
      </PersonalisationMask>);
    } else {
      if (this.gameState.currentLevelNumber !== undefined &&
        this.gameState.currentMailSet) {
        levelView = (<LevelView
          currentLevelNumber={this.gameState.currentLevelNumber}
          maxLevels={this.gameState.maxLevels}
          title={this.gameState.currentMailSet.title}/>);
        scoreView = <ScoreView score={this.gameState.score} />;
      }
      gameBody = (
        <div className="QuizBody">
          <div className="MailList">
            <MailListFeedbackOverlay isActive={this.gameState.activeFeedback}
              isPhishing={this.gameState.currentMailSet?.
                  activeMail?.isPhishing ?? true} />
            <h2>Posteingang</h2>
            <MailListView gameState={this.gameState}
              ref={this.listView} />
          </div>
          {this.getContainerView()}
        </div>
      );
    }
    const hintWarning = (
        this.gameState.hintWarningVisible?
          <HintWarn
            acceptFunction={()=> {
              this.gameState.toggleHintWarn();
              this.gameState.hintUnlocked = true;
              this.gameState.togleHint();
            }}
            rejectFunction={()=> this.gameState.toggleHintWarn()}
            costsPerHint={this.gameState.currentMailSet?.POINTS.HINT_COSTS}
          /> : ''
    );

    return (
      <div className="PhishingQuiz">
        <HintView gameState={this.gameState}/>
        {hintWarning}
        <HeaderView
          mail={this.gameState.mailHeaderVisibleFor}
          close={() => this.gameState.closeMailHeader()}/>
        <div className="GameHead">
          <a href="https://bakgame.de/">
            <img className="Logo" alt="BAK Logo" src={BAKManLogo}
              width="28" height="28"></img>
          </a>
          <span className="GameName">PhishingQuiz</span>
          <div className="GameHeadInfo">
            {levelView}
            {scoreView}
          </div>
        </div>
        <div className="GameBody">
          {gameBody}
        </div>
        <ToastContainer autoClose={1500}/>
        <div className="GameFooter">
          <a className="FooterLink" href="https://www.bakgame.de/impressum/">
      Impressum</a>
          <br/>
          <a className="FooterLink" href="https://www.bakgame.de/datenschutz/">
            Datenschutz</a>
        </div>
      </div>
    );
  }
}

export default PhishingQuiz;
