import React from 'react';
import './PersonalisationMask.scss';
import GameState from '../logic/GameState';


interface PersonalisationMaskProps {
  gameState: GameState,
}

interface PersonalisationMaskState {
  lastName: string,
  firstName: string,
  addressation: string,
  neutral: string,
}

/** Personalisation form */
export default class PersonalisationMask extends
  React.Component<PersonalisationMaskProps, PersonalisationMaskState> {
  /** default constructor */
  constructor(props: PersonalisationMaskProps) {
    super(props);
    this.state = {
      lastName: '',
      firstName: '',
      addressation: '',
      neutral: '',
    };
  }

  /** handle */
  handleInputChange = (event: React.ChangeEvent<
    HTMLInputElement>)=> {
    const value = event.target.value;
    const name = event.target.name;
    if (name == 'neutral' &&
      this.state.addressation != 'Herr' &&
      this.state.addressation != 'Frau') {
      this.setState({'addressation': value});
    }
    this.setState(
      {[name]: value} as Pick<PersonalisationMaskState,
        keyof PersonalisationMaskState>);
  }

  /** override submit function */
  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const {firstName, lastName, addressation} = this.state;
    this.props.gameState.setPersonalisation(firstName, lastName, addressation);
    this.props.gameState.startGame();
  }


  /** builds email from state with defaults for first and lastname */
  getEmail(): string {
    return (this.state.firstName ? this.state.firstName : 'vorname') + '.' +
      (this.state.lastName ? this.state.lastName : 'nachname') + '@kmu.de';
  }

  /** render function */
  render() {
    return (
      <div className='PersonalisationMask ExplainWrapper'>
        <h1 className='ExplainTitle'>Personalisierung</h1>
        <div className='ExplainBody'>
          Im Folgenden können sie einige Angaben zur Personalisierung
          der Emails machen, um das Spielerlebnis zu verbessern.
          Sie Arbeiten im Spielszenario für die fiktive Firma &bdquo;KMU&ldquo;.
          Die Website bzw. Email Adresse der Firma ist &bdquo;kmu.de&ldquo;.
          Die angegebenen Informationen verbleiben auf ihrem Gerät und werden
          nicht an Server übertragen.
          <br/>
        </div>
        <form name='personalisation' onSubmit={(e)=>this.handleSubmit(e)}>
          <fieldset id='addressation'>
            <legend>Anrede</legend>
            <input type='radio' id='male' name='addressation'
              required={true}
              onChange={(e)=>this.handleInputChange(e)} value='Herr'></input>
            <label htmlFor='male'>Herr</label>
            <input type='radio' id='female' name='addressation'
              required={true}
              onChange={(e)=>this.handleInputChange(e)} value='Frau'></input>
            <label htmlFor='female'>Frau</label>
            <label htmlFor='other' hidden={true}>Sonstige</label>
            <input type='radio' id='other'
              name='addressation' required={true}
              value={this.state.neutral}
              onChange={(e)=>this.handleInputChange(e)}></input>
            <label htmlFor='neutral' hidden={true}>Neutral</label>
            <input type='text' name='neutral' id='neutral'
              required={this.state.addressation != 'Herr' &&
                this.state.addressation != 'Frau'}
              onChange={(e)=>this.handleInputChange(e)}
              value={this.state.neutral}>
            </input>
          </fieldset>
          <fieldset>
            <label htmlFor='firstName'>
              Vorname:
            </label>
            <input type='text' id='firstName' name='firstName'
              onChange={(e)=>this.handleInputChange(e)}
              required={true}
              value={this.state.firstName}></input>
          </fieldset>
          <fieldset>
            <label htmlFor='lastName'>Nachname:</label>
            <input type='text' id='lastName' name='lastName'
              onChange={(e)=>this.handleInputChange(e)}
              required={true}
              value={this.state.lastName}></input>
          </fieldset>
          <fieldset>
            <label>Email Adresse:</label>
            <input className="EmailInput" type='email'
              value={this.getEmail()} readOnly={true}>
            </input>
          </fieldset>
          <input className='DesignBTN confirmBTN' type='submit' value='Ok'>
          </input>
        </form>
      </div>
    );
  }
}
