import {makeObservable, observable, action, computed} from 'mobx';
import MailSet from './MailSet';
import GameHistory from './GameHistory';
import {JsonMail} from 'src/api/JsonMailApi';

export enum GamePages {
  loading,
  explain,
  personalisation,
  game,
  finish,
}

/** Game State for PhishingQuiz **/
export default class GameState {
  activeFeedback = false;
  score = 0;
  page: GamePages = GamePages.loading;
  currentMailSet: MailSet | undefined;
  levels: MailSet[] = [];
  gameHistory: GameHistory = new GameHistory;
  hintVisible = false;
  hintUnlocked = false;
  hintWarningVisible = false;
  mailHeaderVisibleFor: JsonMail | undefined;
  firstName = 'Max';
  lastName = 'Mustermann';
  addressation = 'Herr';
  uuid = '';
  /** Game Types: **/
  // 0: neither instant feedback nor custimization
  // 1: instant feedback but no customization
  // 2: customization but no instant feedback
  // 3: both instant and custom feedback
  gameType = 0;

  currentLevelNumber: number|undefined;
  maxLevels = 0;
  /** Constructor making it observable **/
  constructor() {
    makeObservable(this, {
      activeFeedback: observable,
      score: observable,
      page: observable,
      currentMailSet: observable,
      hintVisible: observable,
      hintWarningVisible: observable,
      mailHeaderVisibleFor: observable,
      firstName: observable,
      lastName: observable,
      addressation: observable,
      uuid: observable,
      gameType: observable,
      email: computed,
      submit: action,
      selectNextLevel: action,
      startGame: action,
      init: action,
      openPersonalisationMask: action,
      removeCurrentMail: action,
      switchFeedback: action,
      togleHint: action,
      currentLevelNumber: observable,
      maxLevels: observable,
      reduceScoreOnPhishingLink: action,
      toggleHintWarn: action,
      showMailHeader: action,
      setPersonalisation: action,
      setUserID: action,
    });
  }

  /** Personalisation */

  /** personalisation parameters */
  setPersonalisation(firstName:string, lastName: string, addressation: string) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.addressation = addressation;
  }

  /** construct email from firstname and lastname */
  get email() {
    return `${this.firstName}.${this.lastName}@kmu.de`;
  }

  /** Submit a solution **/
  submit(isPhishing: boolean): number {
    if (!this.currentMailSet) {
      throw Error('current mailset is null');
    }
    const {isCorrect, points} = this.currentMailSet.score(isPhishing);
    const mail = this.currentMailSet.activeMail;
    let maxPoints;
    if (mail != undefined) {
      if (mail.isPhishing) {
        maxPoints = this.currentMailSet.POINTS.PHISHING_MAIL_CORRECT;
      } else {
        maxPoints = this.currentMailSet.POINTS.TRUSTED_MAIL_CORRECT;
      }
      this.gameHistory.add({mail, isCorrect}, maxPoints);
      this.score += points;
    }
    return points;
  }

  /** Remove current mail (after submit) */
  removeCurrentMail() {
    if (!this.currentMailSet) {
      throw Error('current mailset is null');
    }
    const mail = this.currentMailSet.activeMail;
    if (mail != undefined) {
      this.currentMailSet.deleteEntry(mail);
    }
  }

  /** set next level from levels array as current mail set
   * shows finish page if no level is left
   */
  selectNextLevel() {
    const next = this.levels.shift();
    if (next !== undefined) {
      this.currentMailSet = next;
      if (this.currentLevelNumber !== undefined) {
        this.currentLevelNumber++;
        if (this.currentLevelNumber == 1) {
          this.score = 0;
        }
      } else {
        this.currentLevelNumber = 0;
      }
      this.gameHistory.pushCurrentSolutionLevel();
      this.gameHistory.addSolutionLevel(this.currentMailSet.title,
          this.currentLevelNumber);
    } else {
      this.gameHistory.pushCurrentSolutionLevel();
      this.page = GamePages.finish;
    }
  }

  /** Start the first level **/
  startGame() {
    this.page = GamePages.game;
    this.maxLevels = this.levels.length - 1;
    this.selectNextLevel();
  }

  /** Set the state initialized and ready to use **/
  init() {
    this.page = GamePages.explain;
  }

  /** open personalisation Form */
  openPersonalisationMask() {
    if (this.gameType == 2 || this.gameType == 3) {
      this.page = GamePages.personalisation;
    } else {
      this.startGame();
    }
  }

  /** Switch active feedback state (instant feedback on/off) */
  switchFeedback() {
    this.activeFeedback = !this.activeFeedback;
  }

  /** Toggle warning, that hints cost points **/
  toggleHintWarn() {
    this.hintWarningVisible = ! this.hintWarningVisible;
  }

  /** Set the hint to the current Mail **/
  togleHint() {
    if (! this.currentMailSet) {
      throw Error('Current Mail Set is undefined.');
    }
    this.hintVisible = !this.hintVisible;
    if (this.hintVisible) {
      this.score += this.currentMailSet.POINTS.HINT_COSTS;
    }
  }

  /** Show mail header overlay for the specified mail **/
  showMailHeader(mail: JsonMail) {
    this.mailHeaderVisibleFor = mail;
  }

  /** Hide mail header overlay **/
  closeMailHeader() {
    this.mailHeaderVisibleFor = undefined;
  }


  /** setter for uuid **/
  setUserID(id: string) {
    this.uuid = id;
  }

  /** Update state when a link is clicked */
  reduceScoreOnPhishingLink(isPhishingLink: boolean) : number {
    let value = 0;
    if (isPhishingLink) {
      value = -5;
    }
    this.score += value;
    return value;
  }

  /** set the game type **/
  setGameType(gameType: number) {
    this.gameType = gameType;
  }
}
