import React from 'react';
import './ListView.scss';
import ListViewItem from './ListViewItem/ListViewItem';


/** The List View props:
 * * onClick is a callback for clicking the element in the list.
 * * renderEntry is a function that renders the jsx representation
 *   in the list.
 * * list is the list of elements contained in the list.
 */
interface ListViewProps<E> {
  onClick: (element: E) => void,
  renderEntry: (element: E, isActive: boolean) => JSX.Element,
  list: E[],
}

/** The Currently selected element is highlighted by default
 * this element is refered as the activeElement. This might
 * be overwritten in the rander implementation, when setting
 * custome background color.
 */
interface ListViewState<E> {
  activeElement?: E;
}


/**
 * A Class to view a List of clickable elements of type E
 *
 * To use this hand over the array list of parameter E
 * and define the render function, which specifies how to represent
 * the element as JSX.Element in the list.
 */
class ListView<E> extends React.Component<ListViewProps<E>, ListViewState<E>> {
  state: ListViewState<E> = {};

  /** Constructor taking the score as Number **/
  constructor(props: ListViewProps<E>) {
    super(props);
  }

  /** Render the List **/
  render() {
    return (
      <div className="ListView">
        {this.generateListItems()}
      </div>
    );
  }

  setActive = (element: E) => {
    this.setState({activeElement: element});
  }

  onClick = (element: E) => {
    if (this.state.activeElement == element ) {
      this.setState({activeElement: undefined});
    } else {
      this.setState({activeElement: element});
    }
    this.props.onClick(element);
  }

  /** generator function to create a listItem
   * for each element in the element list
   * uses list index as key, needs to be adjusted if list can be shuffeled */
  generateListItems(): React.ReactNode {
    return (
      <ul>
        {this.props.list.map((element, index) => {
          return (<ListViewItem<E> key={index}
            element={element} onClick={this.onClick}
            isActive={(element === this.state.activeElement)}
            content={this.props.renderEntry(element,
                (element === this.state.activeElement))}
          />);
        })}
      </ul>
    );
  }
}

export default ListView;
