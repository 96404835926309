import React from 'react';
import './MailListFeedbackOverlay.scss';

interface MailListFeedbackOverlayProps {
  isActive: boolean
  isPhishing: boolean
}

/** Overlay to hide mail list */
class MailListFeedbackOverlay extends
  React.Component<MailListFeedbackOverlayProps> {
  /** Standard Constructor **/
  constructor(props: MailListFeedbackOverlayProps) {
    super(props);
  }

  /** Render function */
  render() {
    const nonPhishHint = `Die Email wurde zwar fälschlicherweise als 
    Phishing markiert,
    dies ist jedoch weniger schlimm als einer Phishing-Mail zu vertrauen.
    `;
    let overlay;
    if (this.props.isActive) {
      overlay = <div className='MailListOverlay'>
        <div>
          <h3>Fehler-Analyse</h3>
          <br/>
          {this.props.isPhishing?'':nonPhishHint}
          <br/>
          Sie können nun rechts die Hinweise
          {this.props.isPhishing? 'rot': 'grün'}
          hinterlegt sehen.
          Wenn Sie mit der Maus über die markierten Stellen fahren,
          wird ein Fenster Ihnen näheres zu dem Hinweis aufzeigen.
        </div>
      </div>;
    } else {
      overlay = null;
    }
    return (overlay);
  }
}

export default MailListFeedbackOverlay;
