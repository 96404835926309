import React from 'react';
import {MailIssues, JsonMail} from 'src/api/JsonMailApi';
import {getIssueText} from '../../logic/InstantFeedbackText';
import './MailBodyView.scss';

interface MailBodyViewProps {
  jsonMail: JsonMail,
  feedbackIsActive: boolean,
  mailbodyLinkFunction: (event: Event) => boolean,
  firstName: string,
  lastName: string,
  addressation: string,
}

/**
 * PhishingQuiz MailView Component
 *
 * This displays an MailView using
 * a json, which contains email header
 * fields and a html body.
 */
class MailBodyView extends React.Component<MailBodyViewProps> {
  /** Default constructor with Props:
   * Do not hand over user defined string.
   * This would lead to XSS!
   **/

  feedback: Map<string, string>;
  /** Default constructor **/
  constructor(props: MailBodyViewProps) {
    super(props);
    this.feedback = getIssueText(props.jsonMail);
  }

  /** Execute function when component is first rendered */
  componentDidMount() {
    this.changeLinkOnClick();
  }

  /** Execute function everytime component is updated */
  componentDidUpdate() {
    this.changeLinkOnClick();
  }

  /** show errors if there are corresponding classes */
  showBody = () => {
    const parser = new DOMParser();
    const bodyAsElement = parser.parseFromString(this.props.jsonMail.body,
        'text/html').body;
    const hyperlinkElements = bodyAsElement.getElementsByTagName('a');
    Array.from(hyperlinkElements).forEach((linkElement) => {
      // If we can stop using innerHTML and add functions properly
      // we can use this to set the function instead of changeLinkOnClick
      // linkElement.onclick = () => {
      //
      // };
      linkElement.classList.add('MailbodyLink');
    });

    // User Defined Content (firstName, lastName and addressation is inserted)
    // Therefore it is important to insert this as text content only but
    // not as HTML to prevent possible XSS!
    const firstNameElements = bodyAsElement.getElementsByClassName(
        'RplcFirstName');
    Array.from(firstNameElements).forEach((el: Element) => {
      el.textContent = this.props.firstName;
    });
    const lastNameElements = bodyAsElement.getElementsByClassName(
        'RplcLastName');
    Array.from(lastNameElements).forEach((el: Element) => {
      el.textContent = this.props.lastName;
    });
    const addressationElements = bodyAsElement.getElementsByClassName(
        'RplcAddressation');
    Array.from(addressationElements).forEach((el: Element) => {
      el.textContent = this.props.addressation;
    });

    if (this.props.feedbackIsActive) {
      for (const errorType in MailIssues) {
        if (typeof errorType === 'string') {
          const errorElements = bodyAsElement.getElementsByClassName(
              'Err'+errorType);
          Array.from(errorElements).forEach((el: Element) => {
            let dataText = this.feedback.get(errorType);
            if (!dataText) {
              dataText = '';
            }
            el.setAttribute('data-text', dataText);
            if (this.props.jsonMail.isPhishing) {
              el.classList.add('ErrorTooltip--phishing');
            } else {
              el.classList.add('ErrorTooltip--regular');
            }
          });
        }
      }
    }
    return bodyAsElement.innerHTML;
  }

  /** Set onclick function for links in mails */
  changeLinkOnClick() {
    const hyperlinkElements = document.getElementsByTagName('a');
    Array.from(hyperlinkElements).forEach((linkElement) => {
      if (linkElement.className.indexOf('MailbodyLink') !== -1) {
        linkElement.onclick = this.props.mailbodyLinkFunction;
      }
    });
  }

  /** Render HTML in Dangerous mode **/
  render() {
    return (
      <div className="MailBodyView"
        dangerouslySetInnerHTML={{__html: this.showBody()}}/>
    );
  }
}

export default MailBodyView;
