import MailSet from './MailSet';
import {JsonMail, getJsonMail} from 'src/api/JsonMailApi';

/** Wrapper for mail Set files to pop them*/
class MailSetFiles {
  phishing: string[];
  trusted: string[];

  /** Constructor taking the files as array **/
  constructor(phishingFiles: string[], trustedFiles: string[]) {
    this.phishing = phishingFiles;
    this.trusted = trustedFiles;
  }

  /** Pop a random file, tryes to use the given type first**/
  popRandom(phishing: boolean): string {
    let dataset = phishing ? this.phishing: this.trusted;
    const max = dataset.length;
    if (max == 0) {
      dataset = !phishing ? this.phishing: this.trusted;
    }
    const index = getRandomBetween(0, max);
    const value = dataset[index];
    dataset.splice(index, 1);
    return value;
  }
}

const MAILS_PER_SET = 6;
const MAX_RATIO = 2/3;

const LEVEL_2_FILE_SET = new MailSetFiles(
    [
      '/PhishingQuiz/phishing/projektzahlen2.json',
      '/PhishingQuiz/phishing/invoice.json',
      '/PhishingQuiz/phishing/Resturlaub_2020_spam.json',
      '/PhishingQuiz/phishing/internerlink.json',
      '/PhishingQuiz/phishing/mail-belegung.json',
    ],
    [
      '/PhishingQuiz/trusted/invoice.json',
      '/PhishingQuiz/trusted/Resturlaub_2020.json',
      '/PhishingQuiz/trusted/bestelleingang.json',
      '/PhishingQuiz/trusted/paket-for-you.json',
    ],
);

const LEVEL_1_FILE_SET = new MailSetFiles(
    [
      '/PhishingQuiz/phishing/bestelleingang.json',
      '/PhishingQuiz/phishing/sparkasse.json',
      '/PhishingQuiz/phishing/klassiker.json',
      '/PhishingQuiz/phishing/passwort-policy.json',
      '/PhishingQuiz/phishing/erpressung.json',
      '/PhishingQuiz/phishing/meeting.json',
    ],
    [
      '/PhishingQuiz/trusted/passwort-policy.json',
      '/PhishingQuiz/trusted/zahlmal_real_offer.json',
      '/PhishingQuiz/trusted/projektzahlen.json',
      '/PhishingQuiz/trusted/vorlagen.json',
    ],
);

const TUTORIAL_FILE_SET = new MailSetFiles(
    [
      '/PhishingQuiz/phishing/tutorial_phishing.json',
    ],
    [
      '/PhishingQuiz/trusted/tutorial_trusted.json',
    ],
);

/** Helper function for random between **/
function getRandomBetween(min: number, max: number): number {
  const num = Math.floor(Math.random() * (max - min)) + min;
  return num;
}

/** Random date, starting with now, but set to between 6 and 18 local time */
function setDateToRandomLastSeven(date: Date) : Date {
  const dayInMillis = 86400000;
  const oneToThree = (Math.floor(Math.random() * 2) + 1) * dayInMillis;
  const mailDate = new Date(new Date(Date.now() - oneToThree).setHours(
      date.getHours(), date.getMinutes()));
  // if weekend then substract another 2 days flat
  return mailDate.getUTCDay() == 0 || mailDate.getUTCDay() == 6 ?
  new Date(mailDate.getTime() - 2 * dayInMillis) : mailDate;
}

/** shuffle the array to mix phishing and not phishing mails **/
function shuffleArray(array: JsonMail[]): JsonMail[] {
  let currentIndex = array.length;
  let randomIndex;

  while (currentIndex != 0) {
    randomIndex = getRandomBetween(0, currentIndex);
    currentIndex --;

    // Swapp the elements
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

/** Get mails from api **/
async function getMailsForLevel(files: MailSetFiles): Promise<JsonMail[]> {
  const level: JsonMail[] = [];
  const ammountOfPhishing = getRandomBetween(MAILS_PER_SET * MAX_RATIO,
      MAILS_PER_SET * (1 - MAX_RATIO));
  const ammountOfTrusted = MAILS_PER_SET - ammountOfPhishing;

  let isPhishing = false;
  for (let j=0; j < 2; j ++) {
    isPhishing = !isPhishing;
    for (let i=0; i < (isPhishing? ammountOfPhishing: ammountOfTrusted); i ++) {
      const path = files.popRandom(isPhishing);
      if (path) {
        const mail: JsonMail = await getJsonMail(path);
        mail.date = setDateToRandomLastSeven(mail.date);
        level.push(mail);
      }
    }
  }
  return shuffleArray(level);
}

/** create a new levels array */
export default async function getLevels(): Promise<MailSet[]> {
  let mails= await getMailsForLevel(TUTORIAL_FILE_SET);
  const TUTORIAL: MailSet = new MailSet(mails, 'Tutorial');
  mails = await getMailsForLevel(LEVEL_1_FILE_SET);
  const LEVEL1:MailSet = new MailSet(mails, '');
  mails = await getMailsForLevel(LEVEL_2_FILE_SET);
  const LEVEL2:MailSet = new MailSet(mails, '');
  return [TUTORIAL, LEVEL1, LEVEL2];
}
