import React from 'react';
import './FinView.scss';


interface FinViewProps {
  userID: string,
  score: number,
}
const URL = 'https://www.umfrageonline.com/c/yxtgwe93';
/**
 * A Class to view a generic finish page
 */
class FinView extends React.Component<FinViewProps> {
  /** Constructor taking the score as Number **/
  constructor(props: FinViewProps) {
    super(props);
  }

  /** Render The score **/
  render() {
    return (
      <div className="FinView">
        <h2>Ende des Spiels</h2>
        <p>Bitte füllen Sie die nachfolgende Umfrage aus</p>
        <button
          className="BtnOK DesignBTN neutralBTN"
          onClick={
            () => {
              window.location.href = URL +
                `?sc=${this.props.score}&userID=${this.props.userID}`;
            }}>
          Weiter zur Umfrage</button>
      </div>
    );
  }
}

export default FinView;
