import React from 'react';

import './PhishingQuizExplainPage.scss';
import ExplainPage from 'src/utils/ExplainPage/ExplainPage';


export interface PhishingQuizExplainPageProps {
  clickEvent: () => void,
  gameType: number,
}

/** The Explain Page, which can be displayed
 * just before the Quiz starts
 **/
class PhishingQuizExplainPage extends
  React.Component<PhishingQuizExplainPageProps> {
  /** Constructor taking a onClick function for the button**/
  constructor(props: PhishingQuizExplainPageProps) {
    super(props);
  }

  /** Render The Explain text **/
  render() {
    const explainText: JSX.Element = (
      <div className="ExplainText">
      Bei diesem Spiel erhalten Sie Zugriff auf ein E-Mail Programm und sollen
      Phishing-Mails aus einer Liste von E-Mails aussortieren. Wie in gängigen
      E-Mail Programmen wird Ihnen eine Liste aus E-Mails in einem Posteingang
      angezeigt. Durch das Klicken auf eine E-Mail wird auf der rechten
      Seite eine Detailansicht mit deren Inhalt sowie weiteren Informationen
      angezeigt.<br/><br/>
      Wenn Sie die ausgewählte E-Mail für eine Phishing E-Mail halten, klicken
      Sie auf den Button<br/>
        <button className="PhishingButton DesignBTN">Phishing</button>
        <br/>
      Wenn Sie die E-Mail für vertrauenswürdig halten,
      klicken Sie auf den Button
        <br/>
        <button className="NoPhishingButton DesignBTN">
        Kein Phishing</button><br/><br/>
      Anschließend erhalten Sie bei richtiger Zuordnung Punkte und
      die nächste E-Mail in der Liste wird automatisch angezeigt.
        {(this.props.gameType % 2 )?
          `Wenn Sie eine
          Phishing-Mail nicht als solche erkannt haben, werden Ihnen die Fehler
          rot
          hinterlegt hervorgehoben. Wenn Sie Näheres zu einem Fehler erfahren
          möchten, können Sie mit dem Mauszeiger über die hervorgehobene Stelle
          fahren.`:` Wenn Sie eine Phishing-Mail nicht als solche erkannt haben
          werden Ihnen Punkte abgezogen.`
        }<br/><br/>
      Falls Sie mal nicht weiter wissen, haben Sie ebenfalls die Möglichkeit,
      einen Tipp pro E-Mail zu erhalten. Entscheiden Sie sich dafür,
      so werden Ihnen ein paar Punkte abgezogen.
        <br/><br/>
      Das erste Level ist ein Tutorial Level, in dem zwei klar gekennzeichnete
      E-Mails vorhanden sind. Nachdem das Tutorial abgeschlossen ist, geht es
      automatisch mit dem ersten Level los.
        <br/><br/>
      Sämtliche im Folgenden verwendeten Firmen- und Markennamen
      werden lediglich exemplarisch verwendet.
      Weitere Hinweise finden Sie in der Fußzeile.
      </div>
    );

    return (
      <ExplainPage
        title="Phishing-Quiz Anleitung"
        clickEvent={this.props.clickEvent}
        text={explainText}
      />
    );
  }
}

export default PhishingQuizExplainPage;
