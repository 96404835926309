import React from 'react';
import {JsonMail} from 'src/api/JsonMailApi';
import MailBodyView from './MailBodyView/MailBodyView';
import MailHeaderView from './MailHeaderView/MailHeaderView';
import './QuizContainer.scss';


interface QuizContainerProps {
  mail: JsonMail,
  firstName: string,
  lastName: string,
  addressation: string,
  feedbackIsActive: boolean,
  scoreFunction (isPhishing: boolean): void,
  continueFunction (): void,
  hintFunction (): void,
  mailbodyLinkFunction (event: Event): boolean,
}


/** A Container to display the email and some buttons
 * for the game controls
 **/
class QuizContainer extends React.Component<QuizContainerProps> {
  /** Constructor setting the props make sure mail is not
   * user defined to avoid xss!
   **/
  constructor(props: QuizContainerProps) {
    super(props);
  }

  /** quiz button click callback wrapper **/
  onQuizButtonClick(isPhishing: boolean) {
    this.props.scoreFunction(isPhishing);
  }

  /** continue button click callback wrapper */
  onContinueButtonClick() {
    this.props.continueFunction();
  }

  /** Render function */
  render() {
    let buttons;
    if (this.props.feedbackIsActive) {
      buttons = <div className="QuizButtons">
        <button id="continueBtn" className="ContinueButton DesignBTN"
          onClick={() => this.onContinueButtonClick()}>Weiter</button>
      </div>;
    } else {
      buttons = <div className="QuizButtons">
        <button className="HintButton DesignBTN"
          onClick={this.props.hintFunction}
        >Tipps Anzeigen</button><br/>
        <button className="PhishingButton DesignBTN"
          onClick={() => this.onQuizButtonClick(true)}>Phishing</button>
        <button className="NoPhishingButton DesignBTN"
          onClick={() => this.onQuizButtonClick(false)}>
          Kein Phishing</button>
      </div>;
    }
    return (
      <div className="QuizContainer">
        <div className="QuizContainerHead">
          <MailHeaderView
            mail={this.props.mail}
            feedbackIsActive={this.props.feedbackIsActive}
            firstName={this.props.firstName}
            lastName={this.props.lastName}
          />
          {buttons}
        </div>
        <MailBodyView jsonMail={this.props.mail}
          feedbackIsActive={this.props.feedbackIsActive}
          mailbodyLinkFunction={this.props.mailbodyLinkFunction}
          firstName={this.props.firstName}
          lastName={this.props.lastName}
          addressation={this.props.addressation}
        />
      </div>
    );
  }
}

export default QuizContainer;
